import { Show, splitProps } from "solid-js";
import { DescriptionProps } from "./model";
export function Description(props: DescriptionProps) {
  return (
    <Show
      when={props.options?.editable}
      fallback={
        <p class={`text-16px font-400 py-5px leading-24px ${props.class ?? ""}`}>{props.elements.description}</p>
      }
    >
      <textarea
        class={` text#n-200 border-dashed border-2 border#n-200 text-16px  overflow-hidden placeholder:text#n-200 p-10px  leading-24px ${
          props.class ?? ""
        }`}
        // onchange={onDescriptionChange}
        value={props.elements?.description}
        onInput={props.events?.onChange}
      />
    </Show>
  );
}
